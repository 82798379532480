const Shop = {
    initialized: false,
    components: {},
    autoInitComponents: true
};

Shop.components.gallery = {
    initialized: false,
    container: undefined,
    items: [],
    selector: ".photo-preview a",
    current: null,
    image: undefined,
    init: function () {
        const items = document.querySelectorAll(this.selector);
        if (items.length > 0) {
            items.forEach(this.apply);
            this.container = document.getElementById("gallery");
            if (!this.container) {
                this.createContainer();
            }
            document.addEventListener("keyup", this.keyUp);
            this.initialized = true;
        }
    },
    apply: function (element) {
        const component = Shop.components.gallery;
        component.items.push(element);
        element.addEventListener("click", component.handler);
    },
    handler: function (event) {
        event.preventDefault();
        const component = Shop.components.gallery;
        const element = event.target.closest("a");
        component.showPhoto(element);
        component.container.querySelector(".next").focus();
    },
    showPhoto: function (element) {
        const component = Shop.components.gallery;
        const image = element.querySelector("img");
        component.image.setAttribute("src", element.href);
        component.image.setAttribute("alt", image.getAttribute("alt"));
        component.image.setAttribute("title", element.getAttribute("title"));
        component.current = parseInt(element.getAttribute("data-index"));
        component.container.classList.remove("hidden");
    },
    createContainer: function () {
        this.container = document.createElement("div");
        this.image = document.createElement("img");
        this.container.setAttribute("id", "gallery");
        this.container.classList.add("hidden");

        const prevButton = document.createElement("button");
        prevButton.classList.add("prev");
        prevButton.addEventListener("click", this.previousImage);
        prevButton.addEventListener("blur", this.blur);
        this.container.append(prevButton);

        const figure = document.createElement("figure");
        const imageContainer = document.createElement("div");
        figure.addEventListener("click", this.hide);
        figure.append(imageContainer);
        figure.append(document.createElement("figcaption"));
        imageContainer.classList.add("image");
        imageContainer.append(this.image);
        this.container.append(figure);

        const nextButton = document.createElement("button");
        nextButton.classList.add("next");
        nextButton.addEventListener("click", this.nextImage);
        nextButton.addEventListener("blur", this.blur);
        this.container.append(nextButton);

        document.querySelector("body").append(this.container);
    },
    nextImage: function () {
        const component = Shop.components.gallery;
        let newIndex;
        if (component.current < component.items.length - 1) {
            newIndex = component.current + 1;
        } else {
            newIndex = 0;
        }
        component.current = newIndex;
        component.showPhoto(component.items[newIndex]);
        component.container.querySelector(".next").focus();
    },
    previousImage: function () {
        const component = Shop.components.gallery;
        let newIndex;
        if (component.current === 0) {
            newIndex = component.items.length - 1;
        } else {
            newIndex = component.current - 1;
        }
        component.current = newIndex;
        component.showPhoto(component.items[newIndex]);
        component.container.querySelector(".prev").focus();
    },
    keyUp: function (event) {
        const component = Shop.components.gallery;
        if (!component.container.classList.contains("hidden")) {
            switch (event.code.toUpperCase()) {
                case 'ESCAPE':
                    component.hide();
                    break;
                case 'ARROWLEFT':
                    component.previousImage();
                    break;
                case "ARROWRIGHT":
                    component.nextImage();
                    break;
            }
        }
    },
    hide: function () {
        const component = Shop.components.gallery;
        component.container.classList.add("hidden");
        component.items[component.current].focus();
    },
    blur: function () {
        const component = Shop.components.gallery;
        const check = function () {
            if (!document.activeElement.matches("#gallery button")) {
                component.hide();
            }
        };
        window.setTimeout(check, 100);
    }
};

Shop.components.headerCart = {
    container: undefined,
    init: function () {
        this.container = document.getElementById("header-cart");
        this.refresh();
    },
    refresh: function () {
        if (this.container) {
            const url = this.container.href;
            const component = this;
            const request = Biovision.jsonAjaxRequest("get", url, function () {
                const response = JSON.parse(this.responseText);
                if (response.hasOwnProperty("meta")) {
                    component.update(response.meta.count);
                }
            });

            request.send();
        }
    },
    update: function (newCount) {
        if (this.container) {
            this.container.dataset.count = newCount;
        }
    }
}

Shop.components.itemPrice = {
    selector: ".js-item",
    container: undefined,
    ruleSelector: ".js-pricing-rule",
    rules: {},
    optionSelector: ".js-item-option",
    options: [],
    updateButtonSelector: ".js-recalculate",
    updateButton: undefined,
    laminationSelector: ".js-item-lamination",
    laminationContainer: undefined,
    laminations: [],
    materialColorSelector: ".js-item-material-color",
    materialColorContainer: undefined,
    materialColors: [],
    controlSelector: ".js-cart-control",
    orderButton: undefined,
    orderQuantity: undefined,
    messageContainer: undefined,
    priceSelector: ".js-item-price",
    priceContainer: undefined,
    init: function () {
        this.container = document.querySelector(this.selector);
        if (this.container) {
            this.initRule();
            this.collectOptionLists();
            this.collectLaminations();
            this.collectMaterialColors();
            this.initUpdateButton();
            this.initControl();
            this.priceContainer = this.container.querySelector(this.priceSelector);
            this.update();
        }
    },
    initRule: function () {
        const component = this;
        this.container.querySelectorAll(this.ruleSelector).forEach(function (input) {
            const key = input.dataset.key;
            component.rules[key] = input;
            input.addEventListener("blur", component.update);
        });
    },
    collectOptionLists: function () {
        const component = this;
        this.container.querySelectorAll(this.optionSelector).forEach(function (option) {
            component.options.push(option);
            if (['SELECT', 'INPUT'].includes(option.tagName)) {
                option.addEventListener("change", component.update);
            }
        });
    },
    collectLaminations: function () {
        this.laminationContainer = this.container.querySelector(this.laminationSelector);
        if (this.laminationContainer) {
            const component = this;
            this.laminationContainer.querySelectorAll('input[type="radio"]').forEach(function (lamination) {
                component.laminations.push(lamination);
                lamination.addEventListener("click", component.laminationChanged);
            });
        }
    },
    collectMaterialColors: function () {
        this.materialColorContainer = this.container.querySelector(this.materialColorSelector);
        if (this.materialColorContainer) {
            const component = this;
            this.materialColorContainer.querySelectorAll('input[type="radio"]').forEach(function (materialColor) {
                component.materialColors.push(materialColor);
                materialColor.addEventListener("click", component.materialColorChanged);
            });
        }
    },
    sanitizeRuleValues: function () {
        Object.entries(this.rules).forEach(function (pair) {
            const input = pair[1];
            if (parseInt(input.value) < parseInt(input.getAttribute('min'))) {
                input.value = input.getAttribute('min');
            }
            if (parseInt(input.value) > parseInt(input.getAttribute('max'))) {
                input.value = input.getAttribute('max');
            }
        });
    },
    laminationChanged: function () {
        const component = Shop.components.itemPrice;
        component.update();
    },
    materialColorChanged: function () {
        const component = Shop.components.itemPrice;
        component.update();
    },
    initUpdateButton: function () {
        this.updateButton = this.container.querySelector(this.updateButtonSelector);
        if (this.updateButton) {
            const component = this;
            this.updateButton.addEventListener("click", component.update);
        }
    },
    initControl: function () {
        const container = this.container.querySelector(this.controlSelector);
        if (container) {
            this.orderQuantity = container.querySelector("input");
            this.orderButton = container.querySelector("button");
            this.messageContainer = container.querySelector(".message");
            this.messageContainer.addEventListener("click", function () {
                this.classList.add("hidden");
            });

            const component = this;
            this.orderButton.addEventListener("click", component.order);
        }
    },
    variation: function () {
        const variations = [];
        const rules = [];
        let result = '';

        this.options.forEach(function (option) {
            if (option.tagName === "SELECT") {
                variations.push(option.options[option.selectedIndex].value);
            } else {
                variations.push(option.dataset.value);
            }
        });

        if (this.rules.width && this.rules.height) {
            rules.push(this.rules.width.value);
            rules.push(this.rules.height.value);
        }

        result = variations.join(",");
        if (rules.length > 0) {
            result += ";" + rules.join(",");
        }

        return result;
    },
    laminationId: function () {
        if (this.laminationContainer) {
            return this.laminationContainer.querySelector(":checked").value;
        } else {
            return 0;
        }
    },
    materialColorId: function () {
        if (this.materialColorContainer) {
            return this.materialColorContainer.querySelector(":checked").value;
        } else {
            return 0;
        }
    },
    update: function (event) {
        const component = Shop.components.itemPrice;
        if (component.container) {
            component.sanitizeRuleValues();

            const laminationId = component.laminationId();
            let url = component.container.dataset.priceUrl + '?variation=' + encodeURIComponent(component.variation());
            if (laminationId > 0) {
                url += '&lamination=' + laminationId;
            }

            const request = Biovision.newAjaxRequest('GET', url, function () {
                const response = JSON.parse(this.responseText);
                if (response.hasOwnProperty('data')) {
                    component.priceContainer.innerHTML = response.data.price;
                }
            });

            request.send();
        }
    },
    order: function () {
        const component = Shop.components.itemPrice;
        const url = component.container.dataset.orderUrl;
        const data = {
            variation: component.variation(),
            lamination: component.laminationId(),
            material_color: component.materialColorId(),
            quantity: component.orderQuantity.value
        };
        const request = Biovision.jsonAjaxRequest('PUT', url, function () {
            const response = JSON.parse(this.responseText);

            if (response.hasOwnProperty('data')) {
                Shop.components.headerCart.update(response.data.count);
                component.messageContainer.innerHTML = response.data.message;
                component.messageContainer.classList.remove('hidden');
            }
        });

        request.send(JSON.stringify(data));
    }
}

Shop.components.cartItems = {
    priceSelector: ".js-cart-price",
    itemSelector: ".js-cart-item",
    items: [],
    priceContainers: [],
    removeButtons: [],
    quantityFields: [],
    init: function () {
        const component = this;
        document.querySelectorAll(this.priceSelector).forEach(function (element) {
            component.priceContainers.push(element);
        });
        document.querySelectorAll(this.itemSelector).forEach(this.apply);
    },
    apply: function (container) {
        const component = Shop.components.cartItems;
        component.items.push(container);
        const quantityInput = container.querySelector("input");
        if (quantityInput) {
            component.quantityFields.push(quantityInput);
            quantityInput.addEventListener("change", component.quantityChanged);
        }
        const removeButton = container.querySelector(".js-remove-item");
        if (removeButton) {
            component.removeButtons.push(removeButton);
            removeButton.addEventListener("click", component.handleRemove);
        }
    },
    updatePrice: function (newValue) {
        this.priceContainers.forEach(function (element) {
            element.innerHTML = newValue;
        });
    },
    quantityChanged: function (e) {
        const component = Shop.components.cartItems;
        const input = e.target;
        const container = input.closest(component.itemSelector);
        const oldQuantity = parseInt(container.dataset.quantity);
        const newQuantity = parseInt(input.value);
        const delta = newQuantity - oldQuantity;
        if (delta !== 0) {
            const method = delta > 0 ? "put" : "delete";
            const url = container.dataset.url;
            const data = {
                variation: container.dataset.variation,
                lamination: container.dataset.lamination,
                material_color: container.dataset.materialColor,
                quantity: Math.abs(delta)
            }
            const request = Biovision.jsonAjaxRequest(method, url, function () {
                const response = JSON.parse(this.responseText);
                if (response.hasOwnProperty("data")) {
                    component.setState(response.data);
                    container.querySelector(".js-full-price").innerHTML = response.data.price
                }
                container.dataset.quantity = newQuantity;
            });
            request.send(JSON.stringify(data));
        }
    },
    handleRemove: function (e) {
        const component = Shop.components.cartItems;
        const button = e.target;
        const container = button.closest(component.itemSelector);
        const url = container.dataset.url;
        const request = Biovision.jsonAjaxRequest("delete", url, component.processResponse);
        const data = {
            variation: container.dataset.variation,
            lamination: container.dataset.lamination,
            quantity: container.dataset.quantity
        }
        request.send(JSON.stringify(data));
        button.closest("li").remove();
    },
    processResponse: function () {
        const response = JSON.parse(this.responseText);
        if (response.hasOwnProperty("data")) {
            Shop.components.cartItems.setState(response.data);
        }
    },
    setState: function (data) {
        this.updatePrice(data.price);
        Shop.components.headerCart.update(data.count);
    }
}

Shop.components.splashes = {
    selector: ".splash-control",
    controls: [],
    init: function () {
        document.querySelectorAll(this.selector).forEach(this.apply);
        if (this.controls.length > 0) {
            const component = this;
            document.addEventListener("keyup", component.handleKeyUp);
        }
        document.querySelectorAll(".feedback_splash").forEach(function (element) {
            element.addEventListener("click", function (event) {
                const target = event.target;
                if (target.matches(".feedback_splash")) {
                    target.querySelector(".close").click();
                }
            });
        });
    },
    apply: function (element) {
        const component = Shop.components.splashes;
        component.controls.push(element);
        element.addEventListener("click", component.handleClick);
    },
    handleClick: function (event) {
        const component = Shop.components.splashes;
        const checkbox = event.target;
        component.controls.forEach(function (element) {
            if (element !== checkbox) {
                element.checked = false;
            }
        });
    },
    handleKeyUp: function (event) {
        const component = Shop.components.splashes;
        const key = event.code;
        if (key === 'Escape') {
            component.controls.forEach(function (control) {
                control.checked = false;
            });
        }
    }
}

Shop.components.itemOptionSelector = {
    selector: ".js-item .option",
    containers: [],
    radioButtons: [],
    init: function () {
        document.querySelectorAll(this.selector).forEach(this.apply);
    },
    apply: function (container) {
        const component = Shop.components.itemOptionSelector;
        component.containers.push(container);
        container.querySelectorAll('input[type="radio"]').forEach(component.addRadio);
    },
    addRadio: function (input) {
        const component = Shop.components.itemOptionSelector;
        component.radioButtons.push(input);
        input.addEventListener("click", component.handleClick);
    },
    handleClick: function (event) {
        const target = event.target;
        const control = target.closest(".option").querySelector(".list-control");
        if (control) {
            control.checked = false;
        }
        Shop.components.itemPrice.update();
    }
}

Shop.components.newOrderState = {
    selector: "new-order-state",
    list: undefined,
    form: undefined,
    inputs: [],
    init: function () {
        this.list = document.getElementById(this.selector);
        if (this.list) {
            this.form = this.list.closest("form");
            this.list.querySelectorAll('input[type="radio"]').forEach(this.apply);
        }
    },
    apply: function (element) {
        const component = Shop.components.newOrderState;
        component.inputs.push(element);
        element.addEventListener("click", component.handleClick);
    },
    handleClick: function (event) {
        const component = Shop.components.newOrderState;
        const url = component.form.action;
        const request = Biovision.jsonAjaxRequest("put", url);
        const data = {
            new_state: component.form.querySelector(":checked").value
        }
        request.send(JSON.stringify(data));
    }
}

Shop.components.stickyHeader = {
    selector: ".layout-header",
    element: undefined,
    init: function () {
        this.element = document.querySelector(this.selector);
        if (this.element) {
            this.items = this.element.querySelector(".header-items");
            const handler = this.handleScroll;
            window.addEventListener("scroll", handler);
            this.handleScroll();
        }
    },
    handleScroll: function () {
        const component = Shop.components.stickyHeader;
        if (window.innerWidth <= 800) {
            component.element.classList.add("scrolled");
        } else {
            const navOffset = getComputedStyle(component.element).getPropertyValue("--nav-offset");
            const offset = parseFloat(navOffset) * parseFloat(getComputedStyle(document.documentElement).fontSize);
            if (window.scrollY > offset) {
                component.element.classList.add("scrolled");
            } else {
                component.element.classList.remove("scrolled");
            }
        }
    }
}

Shop.components.imageZoom = {
    initialized: false,
    selector: ".carousel-item img",
    elements: [],
    element: undefined,
    init: function () {
        document.querySelectorAll(this.selector).forEach(this.apply);
        // console.log(this.elements);
    },
    apply: function(element) {
        const component = Shop.components.imageZoom;
        component.elements.push(element);
        element.addEventListener('mousemove', component.mouseMove, false);
        element.addEventListener('touchmove', component.touchMove, false);
    },
    mouseMove: function (e) {
        const image_box = this.getBoundingClientRect();
        const x = e.clientX - image_box.left;
        const y = e.clientY - image_box.top;
        Shop.components.imageZoom.position(x, y);
    },
    touchMove: function (e) {
        // Строка ниже отвечает за проматывание страницы во время использования
        // лупы
        //e.preventDefault();
        const image_box = this.getBoundingClientRect();
        const touch = e.touches[0];
        const x = touch.clientX - image_box.left;
        const y = touch.clientY - image_box.top;
        Shop.components.imageZoom.position(x, y);
    },
    position: function (x, y) {
        Shop.components.imageZoom.elements.forEach(function (image) {
            // const image = Shop.components.imageZoom.element;
            const zoom = image.closest("li").querySelector('.zoom');
            if (zoom) {
                const style = zoom.style;
                let percent_x = ((x / image.width) * 100);
                let percent_y = ((y / image.height) * 100);

                if (x > (.01 * image.width)) {
                    percent_x += (.15 * percent_x);
                }
                if (y >= (.01 * image.height)) {
                    percent_y += (.15 * percent_y);
                }

                style.backgroundPositionX = (percent_x - 9) + '%';
                style.backgroundPositionY = (percent_y - 9) + '%';

                style.left = (x - Math.floor(zoom.offsetWidth / 2)) + 'px';
                style.top = (y - Math.floor(zoom.offsetHeight / 2)) + 'px';
            }
        });
    }
};

window.Shop = Shop;

Biovision.components.shop = Shop;
